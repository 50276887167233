<template>
    <div class="memory-index-page">
        <full-loading ref="fullLoading"></full-loading>
        <!-- <asset-tabs :active="'memory'"></asset-tabs> -->
        <div class="page-tools-content">
            <div class="left">
                <el-button type="grey" size="small" round icon="el-icon-plus" v-permission="`memory:memoryStoreRouter:new`" @click="showCreateDialog">{{$t('asset.memory_index_page_button_text_1')}}</el-button>
                <el-button size="small" plain round icon="el-icon-connection" style="margin-left:10px;" v-permission="`memory:memoryStoreRouter:merge`" @click="showMergeDialog">{{$t('asset.memory_index_page_button_text_2')}}</el-button> <!-- :disabled="mergeBtnDisabled" -->
            </div>
            <div class="right">
                <div style="width:110px;" @click="showSearchDialog">
                    <el-input class="cursor-pointer" :placeholder="$t('asset.memory_index_page_search_input_placeholder')" readonly
                        size="small" round suffix-icon="el-icon-search" maxlength="100" clearable 
                        v-model="searchForm.kw" @input="inputSearchEvent"></el-input>
                </div>
                <div class="language-group margin-l-10">
                    <div style="width:105px;">
                        <el-select v-model="searchForm.source" size="small" clearable :placeholder="$t('asset.memory_index_page_search_select_placeholder_1')" @change="selectSearchEvent">
                            <template v-for="(item,key) in LANGUAGE_DICT">
                                <el-option :key="key" :value="key" :label="item.name" :disabled="searchForm.target === item.key"></el-option>
                            </template>
                        </el-select>
                    </div>
                    <div style="margin-left:5px;">
                        <svg-icon name="ib-arrow-right" style="font-size:14px;color:#00000033;"></svg-icon>
                    </div>
                    <div style="width:105px;margin-left:5px;">
                        <el-select v-model="searchForm.target" size="small" clearable :placeholder="$t('asset.memory_index_page_search_select_placeholder_2')" @change="selectSearchEvent">
                            <template v-for="(item,key) in LANGUAGE_DICT">
                                <el-option :key="key" :value="key" :label="item.name" :disabled="searchForm.source === item.key"></el-option>
                            </template>
                        </el-select>
                    </div>
                </div>
                <div style="width:124px;margin-left:10px;" v-if="userInfo.organizationType === 'ENTERPRISE'">
                    <el-select size="small" :placeholder="$t('asset.memory_index_page_search_select_placeholder_3')" clearable v-model="searchForm.teamId" @change="selectSearchEvent">
                        <template v-for="item in teamOptions">
                            <el-option :key="item.value" :value="item.value" :label="item.text"></el-option>
                        </template>
                    </el-select>
                </div>
                <!-- <div style="width:124px;margin-left:10px;">
                    <el-select v-model="searchForm.sort" size="small" placeholder="排序方式" @change="selectSearchEvent">
                        <el-option key="1" value="1" label="按时间排序"></el-option>
                    </el-select>
                </div> -->
            </div>
        </div>
        <div class="card-list" v-if="list.length > 0">
            <template v-for="(item,index) in list">
                <div class="item" :key="index" @click="gotoDetailPage(item)">
                    <div class="left">
                        <el-popover placement="top-start" trigger="hover">
                            <div class="fonts-12 line-height-2">
                                {{$t('asset.memory_index_page_card_item_label_1')}}{{item.name||'—'}}<br>
                                <template v-if="userInfo.organizationType === 'ENTERPRISE' && item.team">
                                    {{$t('asset.memory_index_page_card_item_label_2')}}{{item.team.teamName}}<br>
                                </template>
                                <template v-if="userInfo.organizationType === 'ENTERPRISE'">
                                    {{$t('asset.memory_index_page_card_item_label_3')}}{{item.createBy}}
                                </template>
                            </div>
                            <div slot="reference" class="title">{{item.name||'—'}}</div>
                        </el-popover>
                        <div class="info">
                            <span class="language" v-if="LANGUAGE_DICT">
                                {{LANGUAGE_DICT[item.sourceLang].shortName}}
                                <svg-icon name="ib-arrow-right" style="font-size:12px;margin:0 4px;"></svg-icon>
                                {{LANGUAGE_DICT[item.targetLang].shortName}}
                            </span>
                            <span class="split-line"></span>
                            <span class="size">{{item.nums||'0'}} {{$t('asset.memory_index_page_card_item_label_4')}}</span>
                        </div>
                        <div class="time">{{item.createTime}}</div>
                    </div>
                    <div class="right">
                        <div class="check" @click.stop 
                            :style="selectedMemoryIds.indexOf(item.memId)>-1?{'display':'block'}:{}">
                            <el-checkbox :value="selectedMemoryIds.indexOf(item.memId)>-1" @change="value=>itemCheckChange(value,item.memId)"></el-checkbox>
                        </div>
                        <div style="flex:1;"></div>
                        <div class="type-icon">
                            <svg-icon name="ib-memory"></svg-icon>
                        </div>
                        <div class="more-btn" @click.stop>
                            <el-dropdown placement="bottom" @command="(command)=>handleMoreCommand(command,item)">
                                <svg-icon name="ib-more"></svg-icon>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item command="view" v-permission="`memory:memoryStoreRouter:detail`">{{$t('asset.memory_index_page_card_item_dropdown_label_1')}}</el-dropdown-item>
                                        <el-dropdown-item divided command="downloadExcel" v-permission="`memory:memoryStoreRouter:downloadExcel`">{{$t('asset.memory_index_page_card_item_dropdown_label_2')}}</el-dropdown-item>
                                        <el-dropdown-item command="downloadTMX" v-permission="`memory:memoryStoreRouter:downloadTMX`">{{$t('asset.memory_index_page_card_item_dropdown_label_3')}}</el-dropdown-item>
                                        <el-dropdown-item command="rename" v-permission="`memory:memoryStoreRouter:rename`">{{$t('asset.memory_index_page_card_item_dropdown_label_4')}}</el-dropdown-item>
                                        <el-dropdown-item command="delete" divided v-permission="`memory:memoryStoreRouter:delete`" style="color:#F56C6C;">{{$t('asset.memory_index_page_card_item_dropdown_label_5')}}</el-dropdown-item>
                                    </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <div class="none-list-block" v-else>
            <div class="none-files-content">
                <svg-icon name="ib-empty"></svg-icon>
                <div class="info" v-if="queryType === 'search'">{{$t('asset.memory_index_page_empty_label_1')}}</div>
                <div class="info" v-else>{{$t('asset.memory_index_page_empty_label_2')}}</div>
            </div>
        </div>
        <div class="pagination-content" v-if="list.length > 0 && total > pageSize">
            <el-pagination 
                background 
                @current-change="handleCurrentChange"
                layout="total, prev, pager, next"
                :page-size="pageSize"
                :total="total">
            </el-pagination>
        </div>
        <create-memory ref="createMemoryDialog" @success="initPage"></create-memory>
        <fulltext-search ref="fulltextSearchDialog"></fulltext-search>
        <el-dialog :title="$t('asset.memory_index_page_dialog_1_title')" center :visible.sync="nameDialog.show" destroy-on-close width="480px" top="20px" @close="nameDialog.form.name = ''">
            <el-form label-width="80px" style="margin-right:40px;">
                <el-form-item :label="$t('asset.memory_index_page_dialog_1_form_item_label_1')" required>
                    <el-input :placeholder="$t('asset.memory_index_page_dialog_1_form_item_placeholder_1')" v-model="nameDialog.form.name"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button round plain size="small" @click="nameDialog.show = false">{{$t('asset.memory_index_page_dialog_1_btn_label_1')}}</el-button>
                <el-button round type="primary" size="small" style="margin-left:30px;" :loading="nameDialog.loading" @click="saveRenameBtnEvent">{{$t('asset.memory_index_page_dialog_1_btn_label_2')}}</el-button>
            </div>
        </el-dialog>
        <el-dialog :title="$t('asset.memory_index_page_dialog_2_title')" center :visible.sync="mergeDialog.show" destroy-on-close width="480px" top="20px" @close="closeMergeDialogEvent">
            <el-form label-width="100px" style="margin-right:40px;">
                <el-form-item :label="$t('asset.memory_index_page_dialog_2_form_item_label_1')" required>
                    <el-input :placeholder="$t('asset.memory_index_page_dialog_2_form_item_placeholder_1')" v-model="mergeDialog.form.name"></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-checkbox v-model="mergeDialog.form.isDeleted">{{$t('asset.memory_index_page_dialog_2_form_item_checkbox_label_2')}}</el-checkbox>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button round plain size="small" @click="mergeDialog.show = false">{{$t('asset.memory_index_page_dialog_2_btn_label_1')}}</el-button>
                <el-button round type="primary" size="small" style="margin-left:30px;" :loading="mergeDialog.loading" @click="saveMergeBtnEvent">{{$t('asset.memory_index_page_dialog_2_btn_label_2')}}</el-button>
            </div>
        </el-dialog>
        <alert-dialog ref="alertDialogDom" 
            :message="alertInfo.message" 
            :type="alertInfo.type" 
            :buttonName="alertInfo.buttonName"
            :buttonType="alertInfo.buttonType"
            @confirmEvent="deleteMemoryEvent"></alert-dialog>
    </div>
</template>
<script>
import FullLoading from '@/components/FullLoading';
import CreateMemory from '@/views/assets/components/CreateMemory';
import { mapState } from 'vuex';
import fileDownload from 'js-file-download';
import AssetTabs from '../components/Tabs';
import AlertDialog from '@/components/AlertDialog';
import { memoryApi, commonApi } from '@/utils/api';
import config from '@/utils/config';
import FulltextSearch from '../components/FulltextSearch.vue';
export default {
    components:{AssetTabs, AlertDialog, FullLoading, CreateMemory, FulltextSearch, },
    data(){
        return {
            domainAndEngineList:config.DOMAIN_AND_ENGINE_LIST,
            
            alertInfo:{
                message: this.$t('asset.memory_index_page_alert_info_message_1'),//'确定要删除这个记忆库吗？',
                type:'confirm', //alert ,confirm
                buttonName: this.$t('asset.memory_index_page_alert_info_buttonName_1'), //'确认删除',
                buttonType:'danger',
            },
            queryType:'init',
            searchTimer:null,
            searchForm:{
                kw:'',
                source: '',
                target: '',
                teamId: '',
                sort:'1'
            },
            list:[],
            selectedMemoryIds:[],
            total:0,
            pageSize:25,
            pageNumber:1,
            
            nameDialog:{
                show:false,
                loading:false,
                form:{
                    name:'',
                    memId:null
                }
            },
            mergeBtnDisabled:true,
            mergeDialog:{
                show:false,
                loading:false,
                form:{
                    name:'',
                    isDeleted:false
                }
            },
            teamOptions: [],
        }
    },
    computed:{
        ...mapState({
            engineConfig:state => state.common.engineConfig,
            userInfo: state => state.user.userData,
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
        })
    },
    methods: {
        initPage(){
            let url = `${memoryApi.memoryDB}`+
                        `?pageSize=${this.pageSize}`+
                        `&pageNumber=${this.pageNumber}`;
            if(this.searchForm.kw){
                url = url + `&name=${encodeURI(this.searchForm.kw)}`;
            }
            if(this.searchForm.source){
                url = url + `&sourceLang=${this.searchForm.source}`;
            }
            if(this.searchForm.target){
                url = url + `&targetLang=${this.searchForm.target}`;
            }
            if(this.searchForm.teamId){
                url = url + `&teamId=${this.searchForm.teamId}`;
            }
            this.$refs.fullLoading.setShow();
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    this.list = res.data.records;
                    this.total = res.data.total;
                }
            }).finally(()=>{
                this.$refs.fullLoading.setHide();
            })
        },
        inputSearchEvent(value){
            let self = this;
            if(this.searchTimer){
                clearTimeout(this.searchTimer);
            }
            this.searchTimer = setTimeout(()=>{
                if (value) {
                    self.queryType = 'search';
                }else{
                   self.queryType = 'init'; 
                }
                this.pageNumber = 1;
                self.initPage();
            },500);
        },
        selectSearchEvent(){
            if (this.searchForm.source||this.searchForm.target||this.searchForm.teamId) {
                self.queryType = 'search';
            }else{
                self.queryType = 'init'; 
            }
            this.pageNumber = 1;
            this.initPage();
        },
        handleCurrentChange(num){
            this.pageNumber = num;
            this.initPage();
        },
        itemCheckChange(value,id){
            if (value) {
                this.selectedMemoryIds.push(id);
            }else{
                let idx = this.selectedMemoryIds.indexOf(id);
                this.selectedMemoryIds.splice(idx,1);
            }
            if(this.selectedMemoryIds.length > 1){
                this.mergeBtnDisabled = false;
            }else{
                this.mergeBtnDisabled = true;
            }
        },
        gotoDetailPage(item){
            if (!this.$checkPermission('memory:memoryStoreRouter:detail')){
                this.$message.error(this.$t('asset.memory_index_page_validation_message_1'));
                return;
            }
            this.$ls.set('memoryDbInfo',item);
            this.$router.push({path:'/asset/memory/detail',query:{id:item.memId}});
        },
        handleMoreCommand(command,item){
            switch(command){
                case 'view':
                    this.gotoDetailPage(item);
                    break;
                case 'downloadExcel':
                    this.downloadFileEvent('excel',item)
                    break;
                case 'downloadTMX':
                    this.downloadFileEvent('tmx',item)
                    break;
                case 'rename':
                    this.nameDialog.show = true;
                    this.nameDialog.form.name = item.name;
                    this.nameDialog.form.memId = item.memId;
                    break;
                case 'delete':
                    this.$refs.alertDialogDom.setShow(true);
                    this.selectedMemoryIds = [item.memId];
                    break;
            }
        },
        
        saveRenameBtnEvent(){
            let url = memoryApi.memoryDB;
            let postData=this.nameDialog.form;
            if(!postData.name){
                this.$message.error(this.$t('asset.memory_index_page_validation_message_2'));
                return;
            }
            this.nameDialog.loading = true;
            this.$ajax.put(url,postData).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('asset.memory_index_page_validation_message_3'));
                    this.nameDialog.show = false;
                    this.initPage();
                }
            }).finally(()=>{
                this.nameDialog.loading = false;
            })
        },
        showMergeDialog(){
            if(this.selectedMemoryIds.length < 2){
                this.$message.error(this.$t('asset.memory_index_page_validation_message_4'));
                return;
            }
            let baseItem = this.list.filter(item=>item.memId === this.selectedMemoryIds[0])[0];
            let flag = true;
            for(let k = 0; k < this.selectedMemoryIds.length; k++){
                let id = this.selectedMemoryIds[k];
                for(let i = 0; i < this.list.length; i++){
                    let item = this.list[i];
                    if(id === item.memId){
                        if(baseItem.sourceLang !== item.sourceLang || baseItem.targetLang !== item.targetLang){
                            if(flag) flag = false;
                            break;
                        }
                    }
                }
                if(!flag){
                    break;
                }
            }
            if(!flag){
                this.$message.error(this.$t('asset.memory_index_page_validation_message_5'));
                return;
            }
            this.mergeDialog.show = true;
        },
        closeMergeDialogEvent(){
            this.mergeDialog.form = {
                name:'',
                isDeleted:false
            };
        },
        saveMergeBtnEvent(){
            if(!this.mergeDialog.form.name){
                this.$message.error(this.$t('asset.memory_index_page_validation_message_6'));
                return;
            }
            let url = memoryApi.mergeMemory;
            let postData={
                isDelete:this.mergeDialog.form.isDeleted,
                newMemName:this.mergeDialog.form.name,
                selectedMemIds:this.selectedMemoryIds,
            };
            this.mergeDialog.loading = true;
            this.$ajax.post(url,postData).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('asset.memory_index_page_validation_message_7'));
                    this.mergeDialog.show = false;
                    this.selectedMemoryIds = [];
                    this.mergeBtnDisabled = true;
                    this.mergeDialog.form = {
                        name:'',
                        isDeleted:false
                    };
                    this.initPage();
                }
            }).finally(()=>{
                this.mergeDialog.loading = false;
            });
        },
        deleteMemoryEvent(){
            let url = memoryApi.deleteMemoryBatch;
            console.log()
            this.$ajax.post(url,this.selectedMemoryIds).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('asset.memory_index_page_validation_message_8'));
                    this.selectedMemoryIds = [];
                    this.initPage();
                }
            })
        },
        
        downloadFileEvent(type,item){
            let url = commonApi.batchCreateDownloadTask;
            this.$ajax.post(url, {
                metadataIdList: [item.memId], 
                title: item.name,
                metadataType: 'ASSET_MEM',
                moreOptions: {
                    exportFileType: type,
                }
            }).then(res=>{
                if(res.status === 200){
                    this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true}}));
                }
            }).finally(()=>{
                
            })
        },
        showCreateDialog(){
            this.$refs.createMemoryDialog.setShow(true);
        },
        queryTeamOptions() {
            let url = commonApi.queryTeamFilterOptions;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.teamOptions = res.data.teamFilters;
                }
            });
        },
        showSearchDialog() {
            this.$refs.fulltextSearchDialog.setShow(true, 'memory', this.searchForm.source || '', this.searchForm.target || '', this.searchForm.teamId);
        },
    },
    mounted(){
        this.initPage();
        if(this.$route.query.view && this.$route.query.view === 'new'){
            this.dialog.show = true;
        }
        if(this.userInfo.organizationType === 'ENTERPRISE') {
            this.queryTeamOptions();
        }
    }
}
</script>
<style lang="scss" scoped>
.memory-index-page{
    // padding: 20px;
    .card-list{
        margin: 0 90px;
        display: grid;
        grid-gap: 29px;
        grid-template-columns: repeat(auto-fill,minmax(290px,1fr));
        > .item{
            // width: 320px;
            background: #FFFFFF;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
            padding: 15px 20px;
            display: flex;
            cursor: pointer;
            border: 1px solid #ffffff;
            box-sizing: border-box;
            transition: border .3s;
            &:hover,&:focus{
                border: 1px solid #DFDFDF;
                box-sizing: border-box;
                
                .right{
                    > .check{
                        display: block;
                    }
                    > .type-icon{
                        display: none;
                    }
                    > .more-btn{
                        display: block;
                    }
                    
                }
            }
            .left{
                flex: 1;
                min-width: 0;
                div{
                    font-size: 12px;
                    // line-height: 20px;
                    margin-top: 20px;
                    svg{
                        font-size: 14px;
                        margin-right: 10px;
                    }
                    &.title{
                        margin-top: 0;
                        margin-right: 20px;
                        font-weight: 600;
                        font-size: 15px;
                        // line-height: 22px;
                        overflow:hidden; text-overflow:ellipsis; white-space:nowrap;
                    }
                    .split-line{
                        height: 22px;
                        border-left:1px solid #0000001A; 
                        margin: 0 10px;
                    }
                    &.time{
                        color:#808080;
                    }
                }
            }
            > .right{
                width: 50px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;
                > .check{
                    display: none;
                }
                .type-icon, .more-btn{
                    width:50px;
                    height:24px;
                    line-height:24px;
                    text-align:center;
                    background-color: #EEEEEE;
                    border-radius:50px;
                    svg {
                        font-size: 16px;
                    }
                }
                .more-btn{
                    display: none;
                }
            }
        }
    }
    .pagination-content{
        margin-top: 50px;
        margin-bottom: 60px;
        text-align: center;
    }
}
</style>
